<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">TMS Portal</h1>
      <div class="flex flex-wrap overflow-hidden xl:-mx-3 mt-20 mb-10">
        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/3">
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-green-50 rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-5">
              <p class="mt-3 text-sm">
                Total number of Terminals <strong>(Central Warehouse)</strong>
              </p>
              <h1 class="font-semibold text-3xl">
                {{ filterMetric.centralTotal }}
              </h1>
            </div>
          </div>
        </div>

        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/3">
          <!-- Column Content -->
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-yellow-50 rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-5">
              <p class="mt-3 text-sm">
                Total number of terminals (in each regional center)
              </p>
              <h1 class="font-semibold text-3xl">
                {{ filterMetric.regionalTerminals.total }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap bg-blue-50 py-5 overflow-hidden xl:-mx-3 mt-1">
        <!-- <div class="flex justify-end w-full">
          <div class="float-right xl:my-3 xl:px-3">
            <multiselect
              :taggable="false"
              v-model="filterByState"
              :options="states"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Filter by Location"
              class="w-1/4"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single hidden w-1/4"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.toString() }}</span
                ></template
              >
            </multiselect>
          </div>
        </div>
        <div class="flex w-full xl:px-3">
          <p v-if="filterByState">
            Showing results for {{ filterByState.join(", ") }}
          </p>
        </div> -->
        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/3">
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-10">
              <p class="mt-3 text-sm">Total number of allocated terminals</p>
              <h1 class="font-semibold text-3xl">
                {{ filterMetric.allocated }}
              </h1>
            </div>
          </div>
        </div>

        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/3">
          <!-- Column Content -->
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-10">
              <p class="mt-3 text-sm">Total number of deployed terminals</p>
              <h1 class="font-semibold text-3xl">
                {{ filterMetric.deployed }}
              </h1>
            </div>
          </div>
        </div>

        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/3">
          <!-- Column Content -->
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-10">
              <p class="mt-3 text-sm">Total number of retrieved terminals</p>
              <h1 class="font-semibold text-3xl">
                {{ filterMetric.retrieved }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      filters: ["Day", "Week", "Month", "Year"],
      dateData: null,
      dateFilterActive: false,
      metrics: {},
      filterByState: "",
      states: [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
      ],
    };
  },

  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  computed: {
    ...mapGetters(["GET_TMS_SUMMARY"]),
    filterMetric() {
      return this.GET_TMS_SUMMARY.data;
      // if (this.GET_METRIC.data !== undefined) {
      //   return this.GET_METRIC.data;
      // } else {
      //   return [];
      //   // console.log(this.GET_TERMINALS.data);
      // }
    },
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchMetric();
  },
  components: {
    Multiselect,
  },
  methods: {
    async fetchMetric() {
      this.isLoading = true;
      let payload = {
        dateFilterActive: this.dateFilterActive,
        date: this.dateData,
      };
      try {
        let res = await this.$store.dispatch("FETCH_TMS_SUMMARY");

        this.isLoading = false;

        if (res) {
          this.metrics = res.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchDatedRecords() {
      this.dateFilterActive = true;
      this.currentPage = 1;
      if (
        this.dateData.startDate > this.dateData.endDate ||
        this.dateData.startDate === this.dateData.endDate
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.currentPage = 1;

        let payload = {
          dateFilterActive: this.dateFilterActive,
          date: this.dateData,
        };
        try {
          this.isLoading = true;
          let res = await this.$store.dispatch("FETCH_PORTAL_METRIC", payload);

          this.isLoading = false;
          if (response.status) {
            this.isLoading = false;
            this.metrics = res.data;
          }
        } catch (error) {}
      }
    },
    clearDateFilter() {
      this.dateFilterActive = false;
      this.fetchMetric();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
